import React from 'react';
import { Button } from 'react-bootstrap';

const HomePage = () => {
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-lg-8 offset-lg-2">
          <h1 className="text-center mb-4">Welcome to our eBay Listing Updater</h1>
          <p className="lead text-center">
            Easily update your eBay listings from another eBay listing with just a few clicks!
          </p>
          <div className="text-center">
            <Button variant="primary" href="/login">Get Started</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;