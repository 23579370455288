import React from 'react';
import { Button } from 'react-bootstrap';

const Login = () => {
  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="text-center">
        <img
          src="/assets/images/logo-ph-250x250.jpg"
          style={{height:"150px"}}
          className="rounded-circle mb-4"
          alt="User Avatar"
        />
        <h1 className="mb-3">Login with eBay</h1>
        <p>We only support eBay login.</p>
        <Button variant="primary" onClick={() => window.location.href = "/auth/ebay"}>Login with eBay</Button>
      </div>
    </div>
  );
};

export default Login;